/* eslint-disable react/no-multi-comp */
import * as React from "react";
import IndexLayout from "../layouts";
import { navigate } from "gatsby";
import { useEffect } from "react";

const MissionPage = () => {
  useEffect(() => {
    navigate("/about-us");
  }, []);
  return <IndexLayout></IndexLayout>;
};

export default MissionPage;
